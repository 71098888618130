import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";

// telegram bot partners
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAxC0IMqqCc8fg5vg7u7czJnJstXNtDXss",
  authDomain: "telegrambot-4fadb.firebaseapp.com",
  databaseURL: "https://telegrambot-4fadb-default-rtdb.firebaseio.com",
  projectId: "telegrambot-4fadb",
  storageBucket: "telegrambot-4fadb.appspot.com",
  messagingSenderId: "375173640615",
  appId: "1:375173640615:web:9188ce0538c0d3d5444682",
};

// coinscope backup
// const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyCWLyy4AAXsFL7-5iYjt_gCdpcadW-X1q0",
//   authDomain: "coinscope-backup.firebaseapp.com",
//   projectId: "coinscope-backup",
//   storageBucket: "coinscope-backup.firebasestorage.app",
//   messagingSenderId: "1075695466591",
//   appId: "1:1075695466591:web:d818244491444da29943ea",
// };

// const FIREBASE_CONFIG = {
//   apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
//   authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
//   projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
//   storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
//   messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_SENDERID,
//   appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
//   measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
// };

if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_CONFIG);
}

export default firebase;
