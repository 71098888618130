import { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import Image from "next/image";
import { useCoin } from "@organisms/coin/provider";
import coinImg from "./coin.png";

const StyledImage = styled(Image)`
  ${({ round }) => round && `border-radius: ${round};`}
`;

const getRound = (theme, value) => {
  if (!value) return value;

  if (value === "full") return "100%";

  return theme.global.edgeSize[value] || value;
};

export default function Icon({
  slug,
  size = "medium",
  round = "full",
  ...rest
}) {
  const { icon } = useCoin(slug);
  const [error, setError] = useState(false);

  const theme = useContext(ThemeContext);

  const avatarSize = parseInt(theme.avatar.size[size] || size);

  return (
    <StyledImage
      onError={() => setError(true)}
      src={error ? coinImg : icon}
      alt="coin"
      width={avatarSize}
      height={avatarSize}
      round={getRound(theme, round)}
      {...rest}
    />
  );
}
