export default {
  // firebaseCredential: {
  //   type: "service_account",
  //   project_id: "coinscope-backup",
  //   private_key_id: "7a62e2551f4ca518febdf316a8a7b6166db0ba0c", //process.env.FIREBASE_PRIVATE_KEY_ID,
  //   // private_key:
  //   //   "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC+1kpaevUelrS4\nB/9cYcz4KywU/7BOjqZ9vCjeR0N77doEKOE7UBPDx4IajlaliCvFgLERCnowbfOK\n5VPg6j100h5JoIe6mfhGzYGom8+I5DF6UJuAA1nkYobh9G1ITBQqCbK8hMJ7bxV2\nm35WneStsMGV8wljP2EDTj583fF3GS/j/8csz/qLCRJmN6CoQ+bsOVZ2hORYEVNw\nQe9bOGjyS0SQEq4aVXW3TGsLZ96KyP3szEn2eZMDD20XWIt9wL48kG7o8Bns1Bnm\nZ2DIZsbdM+wzVRZdNS+bI/ZhOUiRT2u8/dxKqCNH90CB0Q3TQU0Lh73GLq2eN1O8\nrQ6nVKTpAgMBAAECggEAB6hGOtPc4AmrXghcHmiCNqGs1s7c/oSfeO4YNGBm/Hhd\nG3ZJy1J5kzfUfZz2gb/cBG8XK8LgMkScgBdBmAXDdNuK1ujNFBdiLJ0fCxVCwjsT\nIoBM168gEpeYJ/A3Llkhz1Ss9P7dZYyIhPDU+e7DTUDevlXj1jVQfuHLL2whveXq\nsDMlbNtRcSJ3EJz5lRrc52c25dE5YmL1PNHoQm6Y+RUOU75ueCvu4Fe4Y59V+4sj\napXWZOiIB4do04yo31FyTyHFTsfpkTOR56Cin9COCSbKP/S4GLAGqCPZgCjmpmm2\n1RkYE5F19C3Li+nS+wAKvFpybne7BT+j4G80ukxfXQKBgQDxKA79DRG4ENWa3QVF\nyNAfuUIRYvBsDa61XomXJbNrwqhJMyUNlEKF+IsXeSp1QjpfgYz0OHWJv0xoCbo5\nce4XDzC1rscUxwvs+jEBrhrPV9eUYkXEq+Y6TiN9wkEqUqolzsGgFVVLymuckQeN\n3o3sShEO8fq7HJegi0VNHzdbFwKBgQDKlVdFf+LFA1hHTqcPOTsvHH+xnpW/joel\nt3Y8VW6XY2ynG/QxlR/IOfFxnoho5+BEfmaaCtUvINXGGo0c/gAeopvgPlt6ku5k\nOy0ZQikhP4Mlm79CXnN2sjrRunr53q/s58jsJ2pAocB/AhRXJorGpLozTJPa6sNi\nD2OvFVP//wKBgDIfBkZ30ef0r8CZEUB4xTDiuyWL77Y1fcs6U9RzRIAwG/Piyl+x\nIcZgauA9EaL/WcyBy37ywRMZDFNPOkI4OKjYQmJwafYajJL7SPY1bmMLucWgSyne\nJGKX3O6FUi/usGhdOYcBCBJRht4qkmxdBhhHKJZ3mooLHkSCzauCwmmPAoGAIO7S\nNywpE1oN2dnBcsodAvWhFKDxOyjSiHAguoIw7k9tdGUo936Eoio5Q/0/s74uSTQ7\nPcOREI5H8mKSmin4nwJUUMoSWQTV2Fl+ZWLKCOuoSE7fcsyj/KHQyhOEtmYrN1oe\n4bUuor8o6fzOjbRoTw6sbnZ+etI7XQ+fZvESrMUCgYBLiiWBJQt8p0d53+lcLhpb\nR2DajmBco/XkngWDQnvXScFUfaRsAa31VG0MeY7oBTTVPJdrLqO3fT7NvrPt+OmT\nM4Z4hKqpM3ODkPGhXhX6SUj0Jwet9pVj0Ql5MDrKH3dTbAFM1tr6K1YtejHbWdn1\npmAxRCpJK5O3sEITZMT34w==\n-----END PRIVATE KEY-----\n",
  //   private_key:
  //     "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCVCfw2jkMhvosb\nHOQdnE8mv+1JRbZevw2IEHdipX9UUFvubF0TDAgX0vyp31KMepcPbiFYSBq5hwIS\n/YXyGb8p9ZGqKSYleYuxz2XFarhU1rpB9OK+HvrAx52P4X4TU4FzBAMYSvv+Pca5\n6OPnKZ3xxgnfaGW00oRDbYnENSw0giWx7VaW/3hBBg8zkz+lH2r3jqGVdSfjz2f6\nbjSxteuNE+dH705NHhj8KyOPB/LQAGdwGQXd9foWs+4izd1izdWW+LzamIWg7GK/\nas+Tj1tJvYzDM0W2VxSCQUev73MeLHO3aGe7JJHKCBH7QivzwcxVL7dhOMmb1zS8\nvF9fMujFAgMBAAECggEABIqLcoQbyPIiRFi5UKrb58CoWirmhChXh4JTVboL0UXD\nj1Yiiq6IRP8rFYGWMjnLOYkJbHdOxsFo3EUST3URk8jSP+uSDuLcHhzFXpV1kv9m\nwuGGn44x5ZOpGhW5i2nOqdkA8rzYkcyDFIewNHGnSN/xmneQkpWcuks8ckvAEl3e\nH0e9gHBqn2u1mHzttl5VzQbSdDI1DzTJ/GW0eabF8ggHu+42Iv+dBxvtFT4jQxpM\nOEiQhjUfDQ2d9qxrqOIts4QbPRClZzxNX368JSzOQUEtGTDb7QamtX2p7Ij6LmH+\n6RkRsQCieiq0PtGpwsAFmvQwdps00wxKBVrE+DNloQKBgQDKH2Nx75m7CyMeMJvQ\n9MEu8xzWFQ9RFoFWbnCdY+YKUsYzgPaaHgLmO/oRHfwvIQC7gpT0kwsSBChbIpGG\nLM8GmgnKhGlF40evLJ4NsboQ/QRv48OwrTVliIJnBsjKw6aQxJ9jdo5OD9gG/w8a\npcLJgM5ifAB3/5PQW7WeZfeqUQKBgQC8xDu4TL7rRHv1E2lMZWrDbPzUNDOT+6sQ\nnNzaRIl8fRXZuqVQlczABdkT1IM+tfB9brq3uUp1Vn6fvr2zpERYcYDk1AN5I0DB\ns4cnMkItqkqV/IED2kCTLTvUucCpIL8t63e6LDaKi0g7gWJPuRHmg3zNamJGOCn5\nas8mo5LGNQKBgQDFKp1ddXcnKJQXTR+SVE9mtyz4AQ8x8Jw2bgFY5yxuAZTqhPEK\nIiB96x/jNrbcWntWbulTM2VnK7saO7xtgKRrMjQ+46+1xyIUVZfQkACLteVwKGSq\noiwmYWWjjtCsSvLe4Y8B/ZKPv5DiMkjPm8dJvWaWV5Q1epD8fZnLbHiOIQKBgCWC\nnRdncLzRlAxEOjVrJccFvkvwv5sVdI3Njfl1ixiIxrHIbBdSXSh/QP62yQnKH6rd\nOQ9PK6Obd3gI8CtlZgtpKqLOGo6U2cZkBWRdkVb/suiRD5YTZaeZYn1IGCfDkRnv\n44/EP2ZSfR0N/do3vX3f/QnYrQSlHnoopldYQ89NAoGACWfch62HenLWnfB+u4LO\nT9ohZmGzIshwJaDUM47Qg9/PUzH2p3HMaNHTgH9VpbQzFiBM3xFPhCFo0aLKGRTQ\n3H1TUK4Asf5Eez2i+yvqKegF7mappjH4MfEXOggrNYCCrLXYs7hJv9FYeegBT+ru\nmGqoki112XkxOAQ4o0pLQZs=\n-----END PRIVATE KEY-----\n",
  //   client_email:
  //     "firebase-adminsdk-fbsvc@coinscope-backup.iam.gserviceaccount.com",
  //   client_id: "107749346989108116150",
  //   auth_uri: "https://accounts.google.com/o/oauth2/auth",
  //   token_uri: "https://oauth2.googleapis.com/token",
  //   auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  //   client_x509_cert_url:
  //     "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-fbsvc%40coinscope-backup.iam.gserviceaccount.com",
  // },

  firebaseCredential: {
    type: "service_account",
    project_id: "telegrambot-4fadb",
    private_key_id: "4f523b46601cb15774401e866bf864ea8baf0bcb",
    private_key: `-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCtWNaN4h/Zqg4T\noyh1ec/LQYnFAn90CQQ/kObrVnWfZzH1mZ99oKXf7oWKefmd0VYQXewsTW0CvTBv\n1GfhKfejx/kACKNCu3D3tgBY62NmT31k9d+ni7fbzfHjVl7sCS9EleyVDCo7QvO1\niKLTmq2miXnJj2l3cXtxFNRf8xRD590QAta0KmHPW/lmZheXtxNJV1t97ZdBQZLp\n2d/JpFKAPNeYs6wfAGV2mRWdqTbxNf0Jak1gwbnX5nTuZJxl2fjhuGxGz7hSfehV\nJ2y9sVlcFmJqqy8x9Ii/KpGFztyhsgv+RyK0kioAfrbJSovjNsYnlf2g/lOynhZJ\n6VUOZVMdAgMBAAECggEAHQnVQ0ZehIV2vzZlSCykMQ3lCNIuzJ7YDdf1vvWoVZAh\nb92QuKhJMTaRO03MkXHoY2yAbDl6KevMaedgkXkIyXBt12IbC0pAFdbwvvSLna9y\nN4/yq+v9XD5nJ2ioFQ1C1E/zJlK33l6MWdwuPplaPpwoASpbhg1GGv7cxsf/HJK7\nHlje2QOx1nLMhNqPfTreBuxzfUvbawIeHBDEIExIr4rM8TIxQDFvOUkIENgEr02J\nPxqc52yXl7SSOGxKV+0EJWwcsJVG71lkhDmFdVLyYAC+T5XWSdo0kHF12VbMwH8f\n5lCjs43FrrdBO/UXMq2ZhJkOm1hMKSAdy+MI4HXjUQKBgQDxE2xfx+RzOEs+q1Mw\nwXspenSmpja6xO6EDhPhSOWqGnRtYtWz29gMu9JP49lGP/lSHOdNbqmUionQgVh7\n2v+/RR+2CChylWDHF7HazrOOYA+iwUlZMnltOijYoFn4TV1Ys/eLAn/bwNgS/MEq\nt1PCnRsltN0LLkhiGpVKtj/T6QKBgQC4FAvVKqMRmWTc1IkbGRpFSm0sKxSwaszG\n3uCtb3HWBh6uaqIc6ONcgIV5Ed8zjNH/N13jdiVd5oM/WliI9OtE4Dmp85G503Hx\nD80XTmOGqbu58+F1v0wifP8OoXf3wiDC4UXPZgT8QwKeu+Q2YEar8+ls/Kc8Mevj\nH+JDpi/JFQKBgDvNBqgnzMqsHzY3h5ZjmGqsnr0sJA1mhogpedL7o0Tz9QehacOI\n+GoIOV5p9d7QRceRtermVZisWQ1o2Is4wxIuUnR0R5xM48nfXdYaSj46JAbKjahb\ntfVbZQB/nnHStyIm2nQYNaFMkAz9t+khutxe3noXCHIalAgJ8hirZt/pAoGAU8hj\n/lkSsZ70xoJCCUBqsaEHgbP2Dbn+jIHDF42C7ZwJeO9169ygJ2kj1xAoXCk7J4OG\ny1Hp/gw+LqarZ2K6drXr6QvAhyjTUpoTigkWyaMVm7SCKPqx53ynBny9wtnr2Wd5\nQ8W2fS7u0VrJq2o0oG07EG0KnR5HtC+953aLh2UCgYEAkz1HWUkpX4gmtgzi250w\ny1XxNyXhnmU05/xg3ES/fZMaO8Pd/n9r6EwiOXYQiVtrDD+bWr0UDUSW/t/6uNRH\nDkWi3nddrvZJkMcqvwCZeS8c8BvNsja2+9DJSYcECq19cG5PCnonz6s8IKhXLRi3\n9G2pum4kwBLTzaOtHjCZ/JA=\n-----END PRIVATE KEY-----\n`,
    client_email:
      "firebase-adminsdk-uph9s@telegrambot-4fadb.iam.gserviceaccount.com",
    client_id: "117268907150446523717",
    auth_uri: "https://accounts.google.com/o/oauth2/auth",
    token_uri: "https://oauth2.googleapis.com/token",
    auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
    client_x509_cert_url:
      "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-uph9s%40telegrambot-4fadb.iam.gserviceaccount.com",
    universe_domain: "googleapis.com",
  },

  promotionTypes: ["Promotion List", "Banner Ads", "Wide Banner", "Popup"],
  promotionDateTypes: ["Promotion List", "Banner Ads", "Wide Banner", "Popup"],
  priceByType: {
    "Promotion List": 0.2,
    "Banner Ads": 0.3,
    "Wide Banner": 0.7,
    Popup: 1,
    AMA: 1.5,
  },
  coinscopeHoldingDiscount: "300,000",
  registeredUsers: 2616112,
  registeredUsersFormatted: "2,6 Million",
  dailyUsers: 50000,
  dailyUsersFormatted: "50,000",
  totalAudits: 2900,
  totalAirdrops: 160,
  telegramUsers: 14000,
  newsletterUsers: 19000,

  bannerWidth: 320,
  bannerHeight: 128,
  wideBannerWidth: 1200,
  wideBannerHeight: 90,

  buybackFee: 10,
  minTokensToHoldAirdrop: 50000,
};
