import { useCoin } from "@organisms/coin/provider";
import { Box, Text, Anchor, Skeleton } from "grommet";
import Image from "next/legacy/image";
import Link from "next/link";
import styled from "styled-components";
import Diamond from "public/assets/trending/diamond.svg";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import Icon from "@organisms/coin/list/icon";

const StyledBox = styled(Box)`
  color: #b6adad;
  &:hover {
    color: white;
  }
`;

const StyledAnchor = styled(Anchor)`
  display: flex;
  flex: 0 0 auto;
`;

export const Container = (props) => {
  return (
    <Box background="#2C3349" align="center" fill>
      <Box
        align="center"
        pad={{ left: "medium" }}
        width={{ width: "100%", max: "xxlarge" }}
        fill
        {...props}
      />
    </Box>
  );
};

export const TrendingTokens = () => {
  const large = useIsLarge();
  return (
    <Box direction="row" gap="xxsmall" flex={false}>
      <Image src={Diamond} alt="diamondIcon" width="12px" heigth="12px"></Image>
      <Text
        size={large ? "xsmall" : "10px"}
        wordBreak="keep-all"
        color="#B6ADAD"
      >
        TRENDING TOKENS
      </Text>
    </Box>
  );
};

const Coin = ({ slug, index, gaCategory = "TrendingTokens" }) => {
  const { name } = useCoin(slug);

  return (
    <Link
      href={`/coin/${slug}`}
      data-ga={`${gaCategory}::click::${slug}::${index}`}
      passHref
      legacyBehavior
    >
      <StyledAnchor weight="normal">
        <StyledBox direction="row" gap="xsmall" align="center">
          <Text size="14px">{index + 1}</Text>
          <Icon slug={slug} round="full" size="small" />
          {/* <Avatar src={icon} round="full" size="small" /> */}
          <Text size="14px">{name}</Text>
        </StyledBox>
      </StyledAnchor>
    </Link>
  );
};

const CoinsContainer = (props) => (
  <Box
    direction="row"
    gap="large"
    overflow={{ horizontal: "auto" }}
    pad={{ vertical: "xsmall" }}
    {...props}
  />
);

export const Coins = ({ coins }) => {
  return (
    <CoinsContainer>
      {coins.map((slug, index) => (
        <Coin key={slug} index={index} slug={slug} />
      ))}
    </CoinsContainer>
  );
};

const Placeholder = ({ index }) => (
  <StyledAnchor href="#" weight="normal">
    <StyledBox direction="row" gap="xsmall" align="center">
      <Text size="14px">{index + 1}</Text>
      <Skeleton background="#38405b" width="24px" height="24px" round="full" />
      <Skeleton
        background="#38405b"
        width="70px"
        height="16px"
        round="xsmall"
      />
    </StyledBox>
  </StyledAnchor>
);

export const CoinsPlaceholder = () => {
  return (
    <CoinsContainer>
      {Array.from(Array(8)).map((_, index) => (
        <Placeholder key={index} index={index} />
      ))}
    </CoinsContainer>
  );
};
